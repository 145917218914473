 import React from "react";

const Footer = (props) => {
  let d = new Date();
  return (
    //Scroll fixed
    <div className={`footer ${props.changeFooter}`} style={{ width: "100%",}}>  
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a href="/" target="_blank" rel="noreferrer">
            Centro Infantil
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "../Dashboard/Content";

import profile from "./../../../images/profile.svg";
import location from "./../../../images/svg/location.svg";
import phone from "./../../../images/svg/phone.svg";

const StudentDetails = () => {
  return (
    <div className="row">
      <div className="col-xl-12">
        {/* Card com os detalhes básicos */}
        <div className="card h-auto">
          <div className="card-header p-0">
            <div className="user-bg w-100">
              <div className="user-svg">
                <svg
                  width="264"
                  height="109"
                  viewBox="0 0 264 109"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.0107422"
                    y="0.6521"
                    width="263.592"
                    height="275.13"
                    rx="20"
                    fill="#FCC43E"
                  />
                </svg>
              </div>
              <div className="user-svg-1">
                <svg
                  width="264"
                  height="59"
                  viewBox="0 0 264 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.564056"
                    width="263.592"
                    height="275.13"
                    rx="20"
                    fill="#FB7D5B"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="user">
                <div className="user-media">
                  <img
                    src={IMAGES.avat9}
                    alt=""
                    className="avatar avatar-xxl"
                  />
                </div>
                <div>
                  <h2 className="mb-0">Karen Hope</h2>
                </div>
              </div>
              <Dropdown className="custom-dropdown">
                <Dropdown.Toggle as="div" className="i-false btn sharp tp-btn ">
                  {SVGICON.dots}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end" align="end">
                  <Dropdown.Item>Option 1</Dropdown.Item>
                  <Dropdown.Item>Option 2</Dropdown.Item>
                  <Dropdown.Item>Option 3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* Detalhes básicos */}
            <div className="row mt-2">
              <div className="col-xl-3 col-xxl-6 col-sm-6">
                <ul className="student-details">
                  <li className="me-2">
                    <Link to={"#"} className="icon-box bg-secondary">
                      <img src={profile} alt="" />
                    </Link>
                  </li>
                  <li>
                    <span>Parente:</span>
                    <h5 className="mb-0">Justin Hope</h5>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-xxl-6 col-sm-6">
                <ul className="student-details">
                  <li className="me-2">
                    <Link to={"#"} className="icon-box bg-secondary">
                      <img src={location} alt="" />
                    </Link>
                  </li>
                  <li>
                    <span>Endereço:</span>
                    <h5 className="mb-0">Matola, Mozal</h5>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-xxl-6 col-sm-6">
                <ul className="student-details">
                  <li className="me-2">
                    <Link to={"#"} className="icon-box bg-secondary">
                      <img src={phone} alt="" />
                    </Link>
                  </li>
                  <li>
                    <span>Telefone:</span>
                    <h5 className="mb-0">+258 84 000 000</h5>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-xxl-6 col-sm-6">
                <ul className="student-details">
                  <li className="me-2">
                    <Link to={"#"} className="icon-box bg-secondary">
                      <img src={phone} alt="" />
                    </Link>
                  </li>
                  <li>
                    <span>Telefone de Emergência:</span>
                    <h5 className="mb-0">+258 84 000 000</h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Card com os detalhes do estudante */}
        <div className="card h-auto ">
          <div className="p-3 d-flex row mt-2">
            <div className="col-xl-2 col-xxl-6 col-sm-6">
              <ul className="student-details">
                <li className="me-2">
                  <i className="fa-solid fa-calendar-days me-2 mt-1 text-primary"
                    style={{ fontSize: "16px" }}></i>
                </li>
                <li>
                  <span className="fw-bold">Data de Nascimento:</span>
                  <h5 className="mb-0">25/05/1998</h5>
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-xxl-6 col-sm-6">
              <ul className="student-details">
                <li className="me-2">
                  <i className="fa-solid fa-venus me-2 mt-1 text-primary"
                    style={{ fontSize: "16px" }}></i>
                </li>
                <li>
                  <span className="fw-bold">Gênero:</span>
                  <h5 className="mb-0">Masculino</h5>
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-xxl-6 col-sm-6">
              <ul className="student-details">
                <li className="me-2">
                  <i className="fa-solid fa-graduation-cap me-2 mt-1 text-primary"
                    style={{ fontSize: "16px" }}></i>
                </li>
                <li>
                  <span className="fw-bold">Classe:</span>
                  <h5 className="mb-0">Bercario</h5>
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-xxl-6 col-sm-6">
              <ul className="student-details">
                
                <li>
                  <span className="fw-bold">Necessidades Especiais:</span>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                      Alergia
                    </li>
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                      Intolerância
                    </li>
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                      Outro
                    </li>
                  </ul>

                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-xxl-6 col-sm-6">
              <ul className="student-details">
                
                <li>
                  <span className="fw-bold">Alergias:</span>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                       Amendoim
                    </li>
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                      Chocolate
                    </li>
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                      Outro
                    </li>
                  </ul>

                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-xxl-6 col-sm-6 mt">
              <ul className="student-details">
                
                <li>
                  <span className="fw-bold">Dietas Preferidas:</span>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                       Vegetariana
                    </li>
                    <li>
                      <i
                        className="fa-solid fa-circle text-secondary me-2"
                        style={{ fontSize: "8px" }}
                      ></i>
                      Outro
                    </li>
                  </ul>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;

import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));

  return (
    <>
      <Modal size="lg" onHide={setModalBox} show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Atualizar informacoes do estudante
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalBox(false)}
            ></button>
          </div>
          <div className="modal-body">
          <div className="row">
             <div className="col-md-6 mb-3">
              <label htmlFor="basic-url" className="form-label d-block">
                Nome do Estudante
              </label>
              <input type="text" className="form-control w-100" />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="basic-url" className="form-label d-block">
                Nome do Responsável
              </label>
              <input type="text" className="form-control w-100" />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="basic-url" className="form-label d-block">
                Endereco
              </label>
              <input type="text" className="form-control w-100" />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Telefone
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput2"
              />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Telefone de Emergencia
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput2"
              />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Classe
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput2"
              />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Necessidades Especiais
              </label>
              <CreatableSelect
                isMulti
                options={[
                  {
                    value: "Atenção especial",
                    label: "Atenção especial",
                  },
                  {
                    value: "Mobilidade reduzida",
                    label: "Mobilidade reduzida",
                  },
                ]}
                placeholder="Selecione ou adicione uma caso nao exista na lista"
                onChange={(selectedOptions) => {
                  console.log(selectedOptions); // selectedOptions will be an array of the selected options
                }}
                isClearable
              />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Alergias
              </label>
              <CreatableSelect
                isMulti
                options={[
                  {
                    value: "Atenção especial",
                    label: "Atenção especial",
                  },
                  {
                    value: "Mobilidade reduzida",
                    label: "Mobilidade reduzida",
                  },
                ]}
                placeholder="Selecione ou adicione uma caso nao exista na lista"
                onChange={(selectedOptions) => {
                  console.log(selectedOptions); // selectedOptions will be an array of the selected options
                }}
                isClearable
              />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Dietas Preferidas
              </label>
              <CreatableSelect
                isMulti
                options={[
                  {
                    value: "Vegetariano",
                    label: "Vegetariano",
                  },
                  {
                    value: "Proteinas",
                    label: "Proteinas",
                  },
                ]}
                placeholder="Selecione ou adicione uma caso nao exista na lista"
                onChange={(selectedOptions) => {
                  console.log(selectedOptions); // selectedOptions will be an array of the selected options
                }}
                isClearable
              />
            </div>
            <div class="col-md-6 mb-3">
              <label htmlFor="exampleFormControlInput2" class="form-label mb-2">
                Estado
              </label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Selecione o estado</option>
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </select>
            </div>
          </div>
           
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => setModalBox(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;

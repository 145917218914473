import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../Dashboard/Content";
import { Button, Dropdown } from "react-bootstrap";
import BasicModal from "../Dashboard/BasicModal";

const tableData = [
  {
    id: "1",
    image: IMAGES.trans1,
    name: "Samantha William",
    tabid: "123456789",
    date: "March 25, 2023",
    parentname: "Mana William",
    city: "Jakarta",
    status: "Active",
    class: "Bercario",
    yearOfEntry: 2023,
  },
  {
    id: "2",
    image: IMAGES.trans2,
    name: "Tony Soap",
    tabid: "123453254",
    date: "June 25, 2023",
    parentname: "James Soap",
    city: "Phoenix",
    status: "Active",
    class: "Bercario",
    yearOfEntry: 2021,
  },
  {
    id: "3",
    image: IMAGES.trans3,
    name: "Karen Hope",
    tabid: "123456452",
    date: "July 25, 2023",
    parentname: "Justin Hope",
    city: "Texas",
    status: "Deactive",
    class: "Bercario",
    yearOfEntry: 2022,
  },
  {
    id: "4",
    image: IMAGES.trans4,
    name: "Jordan Nico",
    tabid: "123442584",
    date: "Apr 25, 2023",
    parentname: "Amanda Nico",
    city: "Dallas",
    status: "Deactive",
    class: "Bercario",
    yearOfEntry: 2020,
  },
  {
    id: "5",
    image: IMAGES.trans5,
    name: "Nadila Adja",
    tabid: "654781357",
    date: "May 25, 2023",
    parentname: "Jack Adja",
    city: "Houston",
    status: "Active",
    class: "Bercario",
    yearOfEntry: 2023,
  },
];

const Students = () => {
  const childRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(tableData);
  const [unchecked, setUnChecked] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterClass, setFilterClass] = useState("");

  const handleFilterNameChange = (e) => setFilterName(e.target.value);
  const handleFilterStatusChange = (e) => setFilterStatus(e.target.value);
  const handleFilterClassChange = (e) => setFilterClass(e.target.value);

  const handleChecked = (id) => {
    let temp = checked.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setChecked(temp);
  };
  const handleCheckedAll = (value) => {
    let temp = checked.map((data) => {
      return { ...data, inputchecked: value };
    });
    setChecked(temp);
    setUnChecked(!unchecked);
  };

  const recordsPage = 15;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  // Aplica o filtro antes de calcular a página
  const filteredData = tableData.filter(
    (item) =>
      (!filterName ||
        (item.name &&
          item.name.toLowerCase().includes(filterName.toLowerCase()))) &&
      (!filterStatus ||
        (item.status &&
          item.status.toLowerCase().includes(filterStatus.toLowerCase()))) &&
      (!filterClass ||
        (item.class &&
          item.class.toLowerCase().includes(filterClass.toLowerCase())))
  );

  // Paginação da lista filtrada
  const records = filteredData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title flex-wrap">
                <div className="mb-3 d-flex ">
                  <input
                    type="text"
                    placeholder="Filter by name"
                    value={filterName}
                    onChange={handleFilterNameChange}
                    className="form-control mb-2 me-2"
                  />
                  <input
                    type="text"
                    placeholder="Filter by status"
                    value={filterStatus}
                    onChange={handleFilterStatusChange}
                    className="form-control mb-2 me-2"
                  />
                  <input
                    type="text"
                    placeholder="Filter by class"
                    value={filterClass}
                    onChange={handleFilterClassChange}
                    className="form-control mb-2"
                  />
                </div>
                <div className="d-flex">
                  <Link to="/add-student" className="btn btn-primary">
                    + Novo Estudante
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div
                  id="example-student_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    className="table-responsive-lg table display dataTablesCard student-tab dataTable no-footer"
                    id="example-student"
                  >
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            onClick={() => handleCheckedAll(unchecked)}
                          />
                        </th>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Data de Nascimento</th>
                        <th>Nome do Parente</th>
                        <th>Contacto</th>
                        <th>Classe</th>
                        <th>Estado</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((item, ind) => (
                        <tr key={ind}>
                          <td>
                            <div className="checkbox me-0 align-self-center">
                              <div className="custom-control custom-checkbox ">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`stud-${item.id}`}
                                  checked={item.inputchecked}
                                  onChange={() => handleChecked(item.id)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`stud-${item.id}`}
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-primary font-w600">
                              ID {item.tabid}
                            </span>
                          </td>
                          <td>
                            <div className="trans-list">
                              <img
                                src={item.image}
                                alt=""
                                className="avatar avatar-sm me-3"
                              />
                              <h4>
                                {" "}
                                <a href="/editar-estudante">{item.name}</a>
                              </h4>
                            </div>
                          </td>
                          <td>
                            <div className="date">{item.date}</div>
                          </td>
                          <td>
                            <h6 className="mb-0">{item.parentname}</h6>
                          </td>
                          <td>
                            <h6 className="mb-0">+258 84 000 000</h6>
                          </td>
                          <td>{item.class}</td>

                          <td>{item.status}</td>
                          <td>
                            <div className="d-flex float-end">
                              <Link
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                title="Ver"
                                to="/student-detail"
                              >
                                <i className="fas fa-eye"></i>
                              </Link>
                              <Link
                                className="btn btn-success shadow btn-xs sharp me-1"
                                title="Ver"
                                to="/editar-estudante"
                              >
                                <i className="fa fa-pencil"></i>
                              </Link>
                              <button
                                className="btn btn-danger shadow btn-xs sharp"
                                title="Apagar"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {lastIndex - recordsPage + 1} to{" "}
                      {tableData.length < lastIndex
                        ? tableData.length
                        : lastIndex}{" "}
                      of {tableData.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers justify-content-center"
                      id="example-student_wrapper"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            } `}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasicModal ref={childRef} />
    </>
  );
};

export default Students;

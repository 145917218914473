import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Card, Table, Modal, Button, Form } from "react-bootstrap";
import {
  fetchClassesApi,
  updateClassApi,
  addClassApi,
  deleteClassApi,
} from "../../../../services/classes-api";
import { useSelector } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Classes = () => {
  const schoolId = useSelector((state) => state.auth.auth.school_id);
  const token = useSelector((state) => state.auth.auth.token);
  const [classes, setClasses] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    monthly_fee: "",
    enrollment_fee: "",
    capacity: "",
    min_age: "",
    max_age: "",
  });

  const fetchClasses = async () => {
    try {
      const data = await fetchClassesApi(schoolId, token);
      setClasses(data);
    } catch (err) {
      setError(err.message);
    }
  };
  
  useEffect(() => {
    if (schoolId && token) fetchClasses();
  }, [schoolId, token]);

  const handleEdit = (cls = null) => {
    // Verifica se é para editar ou adicionar
    setSelectedClass(cls);
    setFormData({
      name: cls ? cls.name : "",
      capacity: cls ? cls.capacity : "",
      monthly_fee: cls ? cls.monthly_fee : "",
      enrollment_fee: cls ? cls.enrollment_fee : "",
      min_age: cls ? cls.min_age : "",
      max_age: cls ? cls.max_age : "",
    });
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      let updatedClass;

      if (selectedClass && selectedClass.id) {
        // Se existir uma classe selecionada, estamos editando
        updatedClass = await updateClassApi(
          schoolId,
          selectedClass.id,
          formData,
          token
        );
        setClasses((prevClasses) =>
          prevClasses.map((cls) =>
            cls.id === selectedClass.id ? updatedClass : cls
          )
        );
      } else {
        // Se não existir uma classe selecionada, estamos adicionando uma nova
        updatedClass = await addClassApi(schoolId, formData, token);
        setClasses((prevClasses) => [...prevClasses, updatedClass]);
      }

      fetchClasses();

      setLoading(false);
      setShowModal(false);
      setFormData({ name: "", amount: "", min_age: "", max_age: "" });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (cls) => {
    // Pergunta ao usuário se ele realmente deseja excluir a classe
    if (!window.confirm("Tem certeza de que deseja excluir essa classe?")) {
      return;
    }
    try {
      await deleteClassApi(schoolId, cls.id, token);
      setClasses((prevClasses) => prevClasses.filter((c) => c.id !== cls.id));
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary btn-sm ms-auto"
                  onClick={() => handleEdit()}
                >
                  + Adicionar
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Mensalidade</th>
                    <th>Taxa de inscrição</th>
                    <th>Capacidade</th>
                    <th>Idade Minima</th>
                    <th>Idade Maxima</th>
                    <th>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    // Exibir skeletons enquanto carrega
                    Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton width={20} />
                        </td>
                        <td>
                          <Skeleton width={100} />
                        </td>
                        <td>
                          <Skeleton width={70} />
                        </td>
                        <td>
                          <Skeleton width={70} />
                        </td>
                        <td>
                          <Skeleton width={50} />
                        </td>
                        <td>
                          <Skeleton width={30} />
                        </td>
                        <td>
                          <Skeleton width={30} />
                        </td>
                        <td>
                          <div className="d-flex">
                            <Skeleton circle={true} width={30} height={30} />
                            <Skeleton
                              circle={true}
                              width={30}
                              height={30}
                              className="ms-2"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : classes.length > 0 ? (
                    // Exibir os dados quando carregados
                    classes.map((cls, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{cls?.name}</td>
                        <td>{cls?.monthly_fee}</td>
                        <td>{cls?.enrollment_fee}</td>
                        <td>{cls?.capacity}</td>
                        <td>{cls?.min_age}</td>
                        <td>{cls?.max_age}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn btn-primary shadow btn-xs sharp me-1"
                              onClick={() => handleEdit(cls)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </button>
                            <button
                              className="btn btn-danger shadow btn-xs sharp"
                              onClick={() => handleDelete(cls)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Exibir mensagem caso não haja classes
                    <tr>
                      <td colSpan="8" className="text-center">
                        No classes found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Adding or Editing */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedClass ? "Editar Classe" : "Adicionar Classe"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="text-danger">{error}</p>}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label>Mensalidade</Form.Label>
              <Form.Control
                type="number"
                value={formData.monthly_fee}
                onChange={(e) =>
                  setFormData({ ...formData, monthly_fee: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formMinAge" className="mt-3">
              <Form.Label>Idade Minima</Form.Label>
              <Form.Control
                type="number"
                value={formData.min_age}
                onChange={(e) =>
                  setFormData({ ...formData, min_age: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formMaxAge" className="mt-3">
              <Form.Label>Idade Maxima</Form.Label>
              <Form.Control
                type="number"
                value={formData.max_age}
                onChange={(e) =>
                  setFormData({ ...formData, max_age: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formCapacity" className="mt-3">
              <Form.Label>Capacidade</Form.Label>
              <Form.Control
                type="number"
                value={formData.capacity}
                onChange={(e) =>
                  setFormData({ ...formData, capacity: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEnrollmentFee" className="mt-3">
              <Form.Label>Taxa de inscrição</Form.Label>
              <Form.Control
                type="number"
                value={formData.enrollment_fee}
                onChange={(e) =>
                  setFormData({ ...formData, enrollment_fee: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {loading ? "Salvando..." : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Classes;
